import React from 'react'
import styled from 'styled-components'
import { AppVideoBanner } from './AppVideoBanner'
import SupplierManagementSolutionBannerData from '../Data/SupplierManagementSolutionBannerData.json'

const AppSupplierManagementSolutionBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 30px 50px 30px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1391px) {
      padding: 130px 20px 50px 20px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .SMBannerSectionOne {
    margin-top: 2rem;
    margin-bottom: auto;
    @media (max-width: 1390px) {
      margin-top: auto;
    }
  }
  .SMBannerHeadingSize {
    max-width: 780px;
    line-height: 1.30435em;
    padding-bottom: 3%;
    font-size: 2.5rem;
    margin-top: auto;
  }
  .SMBannerPara {
    max-width: 950px;
    padding: 10px 20px 0px 0px;
  }
  .SMBannerImage {
    width: 100%;
    height: auto;
    // float: right;
    // @media (max-width: 1390px) {
    //   width: 100%;
    //   float: unset;
    // }
  }
  .whyRaindropListView {
    display: none;
  }
  .SMSectionButton {
    display: flex;
    justify-content: center;
    margin: 40px 0px 20px 0px;
  }
  .SMBannervideo{
    width: 65%;
    height: auto;
    @media (max-width: 1650px) {
      margin-top: 3%;
    }
    @media (max-width: 1390px) {
      width: 100%;
      margin-top: unset;
    }
  }
  .SMBannerContent{
    margin-top: 3%;
    @media (max-width: 1650px) {
      margin-top: unset;
    }
  }
`

export const AppSupplierManagementSolutionBanner = () => {
  return (
    <AppSupplierManagementSolutionBannerWapper>
      {SupplierManagementSolutionBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppVideoBanner
              containSection='containSection'
              EventBannerHeading={data.heading}
              paraOne={data.paraOne}
              paraTwo={data.paraTwo}
              ImagePath={data.ImagePath}
              altName={data.AltName}
              ButtonContent={data.ButtonContent}
              InternalPageShiftUrl={data.ShiftInternalPath}
              ButtonStyle='SMSectionButton'
              BannerSectionOne='SMBannerSectionOne'
              BannerSectionTwo='BannerSectionTwo item-2'
              EventBannerHeadingSize='SMBannerHeadingSize'
              EventBannerPara='SMBannerPara'
              EventBannerImage='SMBannerImage'
              UlStyle='whyRaindropListView'
              EventBannerSectionTwo="SMBannervideo"
              EventBannerSectionOne="SMBannerContent"
            />
          </div>
        )
      })}
    </AppSupplierManagementSolutionBannerWapper>
  )
}
