import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { AppReuseCollapsible } from './AppReuseCollapsible'
import SupplierManagementSolutionCollapsibleData from "../Data/SupplierManagementSolutionCollapsibleData.json"

const AppSupplierManagementSolutionCollapsibleWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SourcingEventsHeadingCollapsible {
    text-align: center;
    padding: 50px 0px 10px 0px;
  }
  .SMKeyPara {
    text-align: left;
    max-width: 1200px;
    margin: auto;
    display: flex;
    padding-bottom: 50px;
    @media (max-width: 1090px) {
      padding: 0px 15px 50px 15px;
    }
  }
  .SMCollapsibleSection {
    padding: 0px 0px 50px 0px;
    max-width: 13900px;
    margin: auto;
    @media (max-width: 1090px) {
      padding: 0px 15px 2rem 15px;
    }
  }
  .SMCollapsibleView {
    display: flex;
    max-width: 1390px;
    justify-content: space-evenly;
    margin: auto;
    padding: 0px 15px 2rem 15px;
    @media (max-width: 7680px) {
      max-width: 1590px;
      flex-wrap: wrap;
    }
  }
  .SMCollapsibleDisplaySection {
    width: 650px;
    padding-bottom: 50px;
  }
  .SMCollapsiblePara {
    text-align: left;
    max-width: 650px;
    margin: auto;
    padding: 10px;
    font-size: 1rem;
  }
  .IncoColor {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    padding-left: 100px;
    padding-right: 10px;
    @media (max-width: 700px) {
      display: none;
    }
  }
  .SMCollapsibleButton {
    height: 50px;
    width: 650px;
    border: none;
    color: #fff;
    background-color: #34a1d5;
    font-size: 20px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    @media (max-width: 700px) {
      height: auto;
      width: 350px;
      text-align: left;
    }
    @media (max-width: 375px) {
      height: auto;
      width: 320px;
      text-align: left;
    }
  }
  .content {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom: 2px solid #34a1d5;
    border-left: 2px solid #34a1d5;
    border-right: 2px solid #34a1d5;
  }
`

export const AppSupplierManagementSolutionCollapsible = () => {
  return (
    <AppSupplierManagementSolutionCollapsibleWapper>
      <div className='HeadingSection'>
        <ReUseHTwotag Heading='Raindrop Supplier Management Key Features' HeadingStyle='SourcingEventsHeadingCollapsible' />
        <ReUsePtag
          para='Staying on top of your suppliers is critical to the success of any business.  Raindrop provides the feature set to move your supplier management from being reactive to proactive.'
          paraStyle='SMKeyPara'
        />
         {/* <ReUsePtag
          para='By automating a lot of manual tasks and having an up-to-data database with all your suppliers with respect to the spend, commodities purchased, contracts in place, next events etc. helps in proving a complete picture of supplier performance, risks, areas of improvement etc. All this tie to one key aspect, Harness the spend leakage. '
          paraStyle='SMKeyPara'
        /> */}
        <div className='SMCollapsibleSection'>
          <div className='SMCollapsibleView'>
            {SupplierManagementSolutionCollapsibleData.map((data, index) => {
              return(
                <div className='SMCollapsibleDisplaySection' key={index}>
                <AppReuseCollapsible ButtonName={data.title} ButtonCenter='' AppReuseCollapsible="AppReuseCollapsible" CollapsibleButton="SMCollapsibleButton" IncoColor="IncoColor"  content="content">
                  <ReUsePtag
                    para={data.Para}
                    paraStyle='SMCollapsiblePara'
                  />
                </AppReuseCollapsible>
              </div>
              )
            })}
          </div>
        </div>
      </div>
    </AppSupplierManagementSolutionCollapsibleWapper>
  )
}
